import { Injector } from '../reflection/injector';
import { GenericClassDecorator, Type } from '../reflection/types';
import { ComponentBase } from './component-base';
import { ComponentData } from './component-data';

export interface ComponentOptions {
    selector: string;
}

export const Component = (options: ComponentOptions): GenericClassDecorator<Type<ComponentBase<any>>> => {
    return (target: Type<ComponentBase<any>>) => {
        ComponentData.registerComponent(target, options);
        Injector.register(target, { type: 'transient'} );
    };
}