import { Guid } from "../../server/core/guid";

interface EventListener {
    callback: (...args: any[]) => Promise<void>;
    id: string;
    owner?: any;
}

export class EventEmitterAsync {

    private _listeners: Map<string, EventListener[]> = new Map<string, EventListener[]>();

    public on(eventName: string, callback: (...args: any[]) => Promise<void>, owner?: any) {
        let eventListeners: EventListener[] = this._listeners.get(eventName);
        if (!eventListeners) {
            eventListeners = [];
            this._listeners.set(eventName, eventListeners);
        }
        const id: string = Guid.newGuid();
        eventListeners.push({
            id,
            callback,
            owner
        });
        return id;
    }
    
    public off(...ids: string[]): void {
        Array.from(this._listeners.keys()).forEach(eventName => {
            let eventListeners: EventListener[] = this._listeners.get(eventName);
            eventListeners = eventListeners.filter(l => ids.includes(l.id));
            this._listeners.set(eventName, eventListeners);
        });
    }

    public offOwner(owner: any): void {
        Array.from(this._listeners.keys()).forEach(eventName => {
            let eventListeners: EventListener[] = this._listeners.get(eventName);
            eventListeners = eventListeners.filter(l => l.owner == owner);
            this._listeners.set(eventName, eventListeners);
        });
    }

    public async emit(eventName: string, ...args: any[]): Promise<void> {
        let eventListeners: EventListener[] = this._listeners.get(eventName);
        if (eventListeners) {
            for (let listener of eventListeners) {
                await listener.callback(...args);
            }
        }
    }
}