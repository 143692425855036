import { MethodParameterInfo } from './types';

export namespace Reflector {

    export function getProperty<TType, TProperty extends keyof TType>(target: TType, key: string): TType[TProperty] {
        if (key in target) { 
            return target[key as TProperty]; 
        }
        else {
            return null;
        }
    }

    export function getProperties(value: any): string[] {
        const properties: string[] = Object.keys(value).filter(item => /[a-zA-Z0-9-_]/.test(item));
        const result: string[] = [];
        properties.forEach(property => {
            if (typeof value[property] === 'object') {
                const subProperties: string[] = getProperties(value[property]);
                result.push(...subProperties.map(subProperty => `${property}.${subProperty}`));
            }
            else {
                result.push(property);
            }
        });
        return result;
    }

    export function getPropertyValue<TValue>(value: any, property: string): TValue {
        if (property.indexOf('.') > 0) {
            const properties: string[] = property.split('.');
            return getPropertyValue(value[properties.first()], properties.getRange(1).join('.'));
        }
        else {
            return value[property];
        }
    }

    export function getMethods(prototype: any): string[] {
        const members: PropertyKey[] = Reflect.ownKeys(prototype);
        return members.filter(member => {
            const memberName = member as string;
            return memberName != 'constructor' && typeof Object.getOwnPropertyDescriptor(prototype, memberName).value == 'function';
        }) as string[];  
    }

    export function getMethodParametersType(prototype: any, methodName: string): string[] {
        const tokens: any[] = (Reflect as any).getMetadata('design:paramtypes', prototype, methodName);
        if (tokens) {
            return tokens.map(token => token.name);
        }
        else {
            return [];
        }
    }

    export function getPropertyType(prototype: any, propertyName: string): string {
        const token: any = (Reflect as any).getMetadata('design:type', prototype, propertyName);
        return token ? token.name: null;
    }

    export function getMethodParametersName(prototype: any, methodName: string): string[] {
        const args: string = prototype[methodName].toString().match(/function\s.*?\(([^)]*)\)/)[1];
        return args.split(',').map(arg => {
            // Ensure no inline comments are parsed and trim the whitespace.
            return arg.replace(/\/\*.*\*\//, '').trim();
        }).filter(arg => {
            // Ensure no undefined values are added.
            return arg;
        });
    }

    export function getMethodParametersInfo(prototype: any, methodName: string): MethodParameterInfo[] {
        const types: string[] = Reflector.getMethodParametersType(prototype, methodName);
        const names: string[] = Reflector.getMethodParametersName(prototype, methodName);
        return names.map(name => {
            const index: number = names.indexOf(name);
            return {
                name,
                type: types[index],
                index,
        }});
    }
}