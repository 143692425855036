export namespace Agent {
    
    export function iPhone(): boolean {
        return navigator.userAgent.toLowerCase().includes('iPhone'.toLowerCase());
    }

    export function android(): boolean {
        return /android/i.test(navigator.userAgent);
    }

    export function iOS(): boolean {
        return /ipad|iphone|ipod/i.test(navigator.userAgent);
    }

    export function safari(): boolean {
        return navigator.vendor.match(/apple/i) && !navigator.userAgent.match(/crios/i) && !navigator.userAgent.match(/fxios/i) && !navigator.userAgent.match(/Opera|OPT\//);
    }
}