import { PropertyDecorator, Type } from '../reflection/types';
import { ComponentBase } from './component-base';
import { ComponentData } from './component-data';

export interface InputOptions {
    isJSON?: boolean;
}

export const Input = (name?: string, options?: InputOptions): PropertyDecorator => {
    return (target: { constructor: Type<ComponentBase<any>> }, propertyKey: string) => {
        ComponentData.registerInput(target.constructor, propertyKey, name, options);
    };
}