import { DictionaryObject } from '../core/types';
import { Injector } from '../reflection/injector';
import { Type } from '../reflection/types';
import { ComponentBase } from './component-base';
import { ChildRefMetadata, ChildrenRefMetadata, ComponentData, ComponentMetadata, EventListenerMetadata, InputMetadata } from './component-data';

export function bootstrap(document: Element | Document, mode: 'load' | 'redirect', ...types: Type<ComponentBase<any>>[]): HTMLElement[] {    
    return createComponents(types, document, mode);
}

function createComponents(types: Type<ComponentBase<any>>[], node: Element | Document, mode: 'load' | 'redirect'): HTMLElement[] {
    const metadata: Map<Type<ComponentBase<any>>, ComponentMetadata> = new Map< Type<ComponentBase<any>>, ComponentMetadata>();
    types.forEach(type => {
        const componentMetadata: ComponentMetadata = ComponentData.getMetadata(type);
        metadata.set(type, componentMetadata);
        const nodes: Element[] = Array.from(node.querySelectorAll(componentMetadata.selector)).filter(n => !n.componentRef);
        if (nodes && nodes.length) {
            nodes.forEach(node => {
                const component: ComponentBase<any> = Injector.get(type, node);                                
                node.componentRef = {
                    metadata: componentMetadata,
                    type,
                    ref: component,
                    isInitialized: false
                }
            });
        }
    });    
    const nodes: HTMLElement[] = Array.from(node.querySelectorAll(types.map(t => metadata.get(t).selector).join(', '))) as HTMLElement[];
    if (nodes && nodes.length) {
        nodes.filter(n => !n.componentRef.isInitialized).forEach(n => initializeComponent(n, nodes, mode));
    }
    return nodes;
}

function initializeComponent(node: Element, nodes: Element[], mode: 'load' | 'redirect'): void {
    if (node.componentRef) {
        const { isInitialized, ref, type, metadata } = node.componentRef;
        if (!isInitialized) {         
            const childNodes: Element[] = nodes.filter(n => node != n && node.contains(n));                
            if (childNodes && childNodes.length) {
                childNodes.forEach(c => initializeComponent(c, nodes, mode));
            }
            findChildRefs(ref, metadata.childRefs);
            findChildrenRefs(ref, metadata.childrenRefs);
            setInputs(ref, metadata.inputs);
            registerEventListeners(ref, metadata.eventListeners);
            ref.onInit(mode);
            node.componentRef.isInitialized = true;
        }
    }    
}

function registerEventListeners(component: ComponentBase<any>, eventListeners: EventListenerMetadata[]): void {
    if (eventListeners.length) {
        eventListeners.forEach(ref => registerEventListener(component, ref));
    }
}

function registerEventListener(component: ComponentBase<any>, eventListener: EventListenerMetadata): void {
    const { eventName, selector, propertyKey, checkEvent } = eventListener;
    if (eventName) {
        const eventNodes: Element[] = !selector ? [component.node as HTMLElement]: Array.from((component.node as HTMLElement).querySelectorAll(selector));
        eventNodes.forEach(eventNode => {
            // eventNode.addEventListener(eventName, e => { 
            //     if (!checkEvent || checkEvent(e)) {
            //         (component as any)[propertyKey](e) 
            //     }   
            // });
            component.addEventListener(eventName, e => { 
                if (!checkEvent || checkEvent(e)) {
                    (component as any)[propertyKey](e) 
                }

            }, eventNode as HTMLElement);
        });           
    }
}

function findChildRefs(component: ComponentBase<any>, childRefs: ChildRefMetadata[]): void {
    if (childRefs.length) {
        childRefs.forEach(ref => findChildRef(component, ref));
    }
}

function findChildRef(component: ComponentBase<any>, ref: ChildRefMetadata): void {
    let child: ComponentBase<any> | Element = null;
    if (ref.selector) {
        const childNode: Element = (component.node as HTMLElement).querySelector(ref.selector);
        if (childNode) {
            child = childNode.componentRef && childNode.componentRef.ref && isComponentType(childNode.componentRef.ref, ref.propertyType) ? childNode.componentRef.ref: childNode;
        }
    }
    else {
        const childNode: Element = Array.from((component.node as HTMLElement).querySelectorAll('*')).find(node => node.componentRef && node.componentRef.ref && isComponentType(node.componentRef.ref, ref.propertyType));
        if (childNode) {
            child = childNode.componentRef.ref;
        }
    }
    if (child) {
        (component as DictionaryObject<any>)[ref.propertyKey] = child;
    }
}

function isComponentType(component: ComponentBase<any>, type: any): boolean {
    return component && (component as any).constructor.name == type;
}

function findChildrenRefs(component: ComponentBase<any>, childrenRefs: ChildrenRefMetadata[]): void {
    if (childrenRefs.length) {
        childrenRefs.forEach(ref => findChildrenRef(component, ref));
    }
}

function findChildrenRef(component: ComponentBase<any>, ref: ChildrenRefMetadata): void {
    let children: (ComponentBase<any> | Element)[] = null;
    if (ref.selector) {
        const childrenNode: Element[] = Array.from((component.node as HTMLElement).querySelectorAll(ref.selector));
        if (childrenNode) {
            children = childrenNode.map(node => node.componentRef && node.componentRef.ref && isComponentType(node.componentRef.ref, ref.propertyType) ? node.componentRef.ref: node);
        }
    }
    else {
        const childrenNode: Element[] = Array.from((component.node as HTMLElement).querySelectorAll('*')).filter(node => node.componentRef && node.componentRef.ref && isComponentType(node.componentRef.ref, ref.propertyType));
        if (childrenNode) {
            children = childrenNode.map(node => node.componentRef.ref);
        }
    }
    if (children) {
        (component as DictionaryObject<any>)[ref.propertyKey] = children;
    }
}

function setInputs(component: ComponentBase<any>, inputs: InputMetadata[]): void {
    if (inputs.length) {
        inputs.forEach(input => setInput(component, input));
    }
}

function setInput(component: ComponentBase<any>, input: InputMetadata): void {
    const name: string = input.name || input.propertyKey;
    const { options, propertyKey, propertyType } = input;
    const { dataset } = component.node;
    if (options && options.isJSON) {
        (component as DictionaryObject<any>)[propertyKey] = dataset[name] ? JSON.parse(dataset[name]): null;    
    }
    else {
        (component as DictionaryObject<any>)[propertyKey] = parseValue(dataset[name], propertyType);
    }    
}

function getInputName(input: InputMetadata): string {
    const name: string = input.name || input.propertyKey;
    const names: string[] = name.split('-').map(n => {
        let result: string = '';
        for (let i: number = 0; i < n.length; i++) {
            const value: string = n[i];
            if (value.isUpper()) {
                result += `-${value.toLowerCase()}`;
            }
            else {
                result += value;
            }
        }
        return result;
    });  
    if (names.length > 1) {
        return names.map((n: string, index: number) => index == 0 ? n: n.toFirstCharUpper()).join('');
    }
    else {
        return names[0];
    }
}

function parseValue(value: string, type: string): any {
    let parsed: any = null;
    if (value) {
        if (typeof value === type.toLowerCase()) {
            parsed = value;
        }
        else {
            switch (type.toLowerCase()) {
                case 'bigint':
                    parsed = BigInt(value as string);
                    break;
                case 'boolean':
                    parsed = typeof value === 'string' ? value == 'true' ? true: value == 'false' ? false: Boolean(value) : Boolean(value);
                    break;
                case 'number':
                    parsed = Number(value);
                    break;
                case 'string':
                    parsed = value.toString();
                    break;
                case 'array':  
                    throw new Error(`Parser: type ${type} not found`);                  
                case 'object':
                    throw new Error(`Parser: type ${type} not found`);
                default:
                    if (type === 'Date') {
                        parsed = new Date(value as string);
                    }
                    else {
                        throw new Error(`Parser: type ${type} not found`);
                    }
            }
        }
    }
    return parsed;
}

