function debounce(func: Function, time: number = 300): Function {
  let timer: NodeJS.Timer = null;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => { 
      func(args); 
    }, time);
  };
}

export default debounce;