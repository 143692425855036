import { Injectable } from "../reflection/injectable";

@Injectable({ type: 'singleton' })
export class ToastService {

    public constructor() {
        window.addEventListener('user-sign-out', () => {
            const toastContainer: HTMLDivElement = document.querySelector('.u-toast-container');
            if (toastContainer) {
                toastContainer.parentNode.removeChild(toastContainer);
            }
        }); 
    }

    public async show(id: string, message: string, buttons: { title: string, isDefault?: boolean, click: (resolve: (result?: any) => void) => void }[], autoHide?: boolean): Promise<any> {
        if (!document.querySelector(`#${id}`)) {
            const promise: Promise<any> = new Promise<any>((resolve: (result?: any) => void) => {
                let toastContainer: HTMLDivElement = document.querySelector('.u-toast-container');
                if (!toastContainer) {
                    toastContainer = document.createElement('div');
                    toastContainer.classList.add('u-toast-container');
                    document.body.appendChild(toastContainer);
                }
                const toastElement: HTMLDivElement = this.createToast(resolve, id, message, buttons, autoHide);
                toastContainer.appendChild(toastElement);
            });
            return promise;
        }
    }   

    private createToast(resolve: (result?: any) => void, id: string, message: string, buttons: { title: string, isDefault?: boolean, click: (resolve: (result?: any) => void) => void }[], autoHide?: boolean): HTMLDivElement {
        const toastElement: HTMLDivElement = document.createElement('div');
        (toastElement as any).resolve = resolve;
        toastElement.id = id;
        toastElement.classList.add('u-toast');
        const toastMessageElement: HTMLDivElement = document.createElement('div');
        toastMessageElement.classList.add('u-toast-message');
        toastMessageElement.innerHTML = message;
        toastElement.appendChild(toastMessageElement);

        if (autoHide) {
            toastElement.addEventListener('click', () => this.hideToast(toastElement, resolve));
        }

        const toastButtonsElement: HTMLDivElement = document.createElement('div');
        toastButtonsElement.classList.add('u-toast-buttons');
        toastElement.appendChild(toastButtonsElement);
        if (buttons && buttons.length) {
            buttons.forEach(b => {
                const buttonElement: HTMLButtonElement = document.createElement('button');
                buttonElement.innerHTML = b.title;
                buttonElement.classList.add('u-toast-button', 'u-button', 'u-button--text');
                buttonElement.addEventListener('click', () => {
                    this.hideToast(toastElement, resolveClick => b.click(resolve));
                });
                if (b.isDefault) {
                    (toastElement as any).resolve = b.click;
                }
                toastButtonsElement.appendChild(buttonElement);
            });
        }
        return toastElement;
    }

    private hideToast(toastElement: HTMLElement, resolve?: (result?: any) => void): void {
        toastElement.parentNode.removeChild(toastElement);
        if (resolve) {
            resolve();
        }
    }

    public hide(id: string): void {
        const toastElement: HTMLDivElement = document.querySelector(`#${id}`);
        if (toastElement) {
            this.hideToast(toastElement, (toastElement as any).resolve);
        }
    }
}