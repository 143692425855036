import { Injector } from './injector';
import { GenericClassDecorator, Type } from './types';

export type InjectableType = 'singleton' | 'transient';
export type InjectableOptions = { type?: InjectableType, token?: string };

export const Injectable = (options?: { type?: InjectableType, token?: string}) : GenericClassDecorator<Type<any>> => {
  return (target: Type<any>) => {
    if (!options) {
      options = {
        type: 'transient'
      };
    }
    if (!options.type) {
      options.type = 'transient';
    }
    Injector.register(target, options);
  };
};
