import { Injectable } from "../reflection/injectable";

export interface HttpClientResponse {
    statusCode?: number;
    statusMessage?: string;
    headers?: any;
    data?: string;
    responseUrl?: string;
}

@Injectable({ type: 'singleton'})
export class HttpService {

    public async get(url: string, data: { headers?: any, query?: any }): Promise<HttpClientResponse> {
        return await this.request('GET', url, data);
    }

    public async post(url: string, data: { headers?: any, query?: any, body?: any }): Promise<HttpClientResponse> {
        return await this.request('POST', url, data);
    }

    public async put(url: string, data: { headers?: any, query?: any, body?: any }): Promise<HttpClientResponse> {
        return await this.request('PUT', url, data);
    }

    public async delete(url: string, data: { headers?: any, query?: any, body?: any }): Promise<HttpClientResponse> {
        return await this.request('DELETE', url, data);
    }

    private async request(method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, data: { headers?: any, query?: any, body?: string }): Promise<HttpClientResponse> {
        return new Promise((resolve, reject) => {
            const { headers, query, body } = data;
            let xhr: XMLHttpRequest = null;
            try {
                xhr = new XMLHttpRequest();
                if (query) {
                    if (typeof query === 'string') {
                        url += `${url.indexOf('?') > 0 ? '&': '?'}${query}`;
                    }
                    else if (Object.keys(query).length) {
                        url += `${url.indexOf('?') > 0 ? '&': '?'}${Object.keys(query)
                            .filter(key => !!query[key])
                            .map(key => `${key}=${query[key]}`)
                            .join('&')}`;
                    }
                }
                xhr.open(method, url, true);
                if (headers) {                
                    Object.keys(headers).forEach(header => xhr.setRequestHeader(header, headers[header]));
                }
                xhr.onload = () => {
                    let response: string = null;
                    try {
                        response = xhr.response;
                    }
                    catch (e: any) {
                        logger.error(e);
                    }
                    let responseHeaders: any = {};
                    const responseHeadersValue: string = xhr.getAllResponseHeaders();
                    if (responseHeadersValue) {
                        responseHeaders = responseHeadersValue.split('\r\n').reduce((acc: any, current: string, i: number) => {
                            const parts: string[] = current.split(': ');
                            acc[parts[0]] = parts[1];
                            return acc;
                        }, {});
                    }
                    xhr.status >= 200 && xhr.status < 300
                        ? resolve({
                            statusCode: xhr.status,
                            statusMessage: xhr.statusText,
                            headers: responseHeaders,
                            data: response,
                            responseUrl: xhr.responseURL
                        })
                        : reject({
                            statusCode: xhr.status,
                            statusMessage: xhr.statusText,
                            headers: responseHeaders,
                            data: response,
                            responseUrl: xhr.responseURL
                        });
                };
                xhr.onerror = e => {
                    reject(e);
                };
                if (body) {
                    xhr.send(body);
                } 
                else {
                    xhr.send();
                }
            } catch (e: any) {
                reject(e);
            }
        });
    }
}