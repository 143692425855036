import { DictionaryObject } from "../core/types";
import { Injectable } from "../reflection/injectable";

@Injectable({ type: 'singleton' })
export class ImageLoaderService {

    private _images: DictionaryObject<{ loaded: boolean, elements: HTMLElement[] }> = {};
    private _observer: IntersectionObserver = null;

    public init(): void {
        this._images = {};
        if (this._observer) {
            this._observer.disconnect();
        }
        this._observer = null;
        const lazyImages: HTMLImageElement[] = Array.from(document.querySelectorAll('[data-src]'));
        lazyImages.forEach(lazyImage => {
            const src: string = lazyImage.dataset.src;
            if (src && src != lazyImage.src) {
                this._images[src] = this._images[src] || { loaded: false, elements: []};
                this._images[src].elements.push(lazyImage);
            }
        });
        const lazyBgImages: HTMLElement[] = Array.from(document.querySelectorAll('[data-bg-src]'));
        lazyBgImages.forEach(lazyBgImage => {
            const src: string = lazyBgImage.dataset.bgSrc;
            this._images[src] = this._images[src] || { loaded: false, elements: []};
            this._images[src].elements.push(lazyBgImage);
        });
        if (window.IntersectionObserver) {
            if (!this._observer) {
                this._observer = new IntersectionObserver(entries => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            const lazyImage: HTMLElement = entry.target as HTMLElement;
                            const src: string = lazyImage.dataset.src || lazyImage.dataset.bgSrc;                        
                            if (src) {
                                this.loadImage(src);
                                this._observer.unobserve(lazyImage);
                                lazyImage.dataset.unobserved = 'true';
                            }
                        }
                    });
                }, {});
            }
            [...lazyImages, ...lazyBgImages].forEach(lazyImage => {
                lazyImage.dataset.observed = 'true';
                this._observer.observe(lazyImage);
            });
        }
        else {
            Object.keys(this._images).forEach(src => this.loadImage(src));
        }
    }
   
    private loadImage(src: string): void {
        const { loaded, elements } = this._images[src] || {};
        if (elements) {
            if (!loaded && this.isPendingLoad(elements)) {
                const imageElement: HTMLImageElement = document.createElement('img');
                imageElement.src = src;
                imageElement.addEventListener('load', () => {
                    elements.forEach(lazyImage => {
                        if (lazyImage.dataset.src) {
                            (lazyImage as HTMLImageElement).src = src;
                        }
                        else {                        
                            lazyImage.style.backgroundImage = `url("${src}")`;
                        }                    
                        setTimeout(() => lazyImage.dataset.loaded = 'true', 100);
                    });
                });
                this._images[src].loaded = true;
            }
        }
    }    

    private isPendingLoad(elements: HTMLElement[]): boolean {
        const isPending: boolean = elements.some(e => {
            if (e.dataset.src) {
                return (e as HTMLImageElement).src != e.dataset.src;
            }
            else {                        
                return e.style.backgroundImage != `url("${e.dataset.bgSrc}")`;
            }  
        });
        return isPending;
    }
}