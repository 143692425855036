declare global {
    interface String {
        replaceAll(search: string, replacement: string): string;
        removeAll(...values: string[]): string;
        toFirstCharLower(): string;
        toFirstCharUpper(): string;      
        isUpper(): boolean;
        format(...params: any[]): string;  
    }
}

export namespace Str {

    export function addExtensionMethods(): void {
        replaceAllMethod();
        removeAllMethod();
        toFirstCharLowerMethod();
        toFirstCharUpperMethod();
        isUpperMethod();
        formatMethod();
    }

    function replaceAllMethod(): void {
        if (!String.prototype.replaceAll) {
            String.prototype.replaceAll = function (search, replacement) {
                return replaceAll(this as string, search, replacement);
            };
        };
    }

    function removeAllMethod(): void {
        if (!String.prototype.removeAll) {
            String.prototype.removeAll = function (...values) {
                return removeAll(this as string, ...values);
            };
        };
    }

    function toFirstCharLowerMethod(): void {
        if (!String.prototype.toFirstCharLower) {
            String.prototype.toFirstCharLower = function () {
                return toFirstCharLower(this as string);
            };
        };
    }

    function toFirstCharUpperMethod(): void {
        if (!String.prototype.toFirstCharUpper) {
            String.prototype.toFirstCharUpper = function () {
                return toFirstCharUpper(this as string);
            };
        };
    }

    function isUpperMethod(): void {
        if (!String.prototype.isUpper) {
            String.prototype.isUpper = function () {
                return isUpper(this as string);
            };
        };
    }

    function formatMethod(): void {
        if (!String.prototype.format) {
            String.prototype.format = function (...params) {
                return format(this as string, ...params);
            };
        };
    }

    function replaceAll(value: string, find: string, replace: string): string {
        let index: number = value.indexOf(find);
        while (index >= 0) {
            value = value.replace(find, replace);
            index = value.indexOf(find);
        }
        return value;
    }

    function removeAll(value: string, ...values: string[]): string {
        if (values && values.length) {
            values.forEach(v => value = replaceAll(value, v, ''));
        }
        return value;
    }

    function toFirstCharLower(value: string): string {
        return `${value[0].toLowerCase()}${value.substr(1)}`;
    }

    function toFirstCharUpper(value: string): string {
        return `${value[0].toUpperCase()}${value.substr(1)}`;
    }

    function isUpper(value: string): boolean {
        return !/[a-z]/.test(value) && /[A-Z]/.test(value);
    }

    function format(value: string, ...parameters: string[]): string {
        let formattedValue: string = value;
        if (parameters != null) {
            for (let i: number = 0; i < parameters.length; i++) {
                if (typeof parameters[i] === 'string')
                    formattedValue = replaceAll(formattedValue, '{{' + i + '}}', parameters[i]);
                else
                    formattedValue = replaceAll(formattedValue, '{{' + i + '}}', parameters[i] != null ? parameters[i].toString() : null);
            }
        }
        return formattedValue;
    }

    export function isJson(value: string): boolean {
        try {
            JSON.parse(value);
        } 
        catch (e) {
            return false;
        }
        return true;
    }

    export function getProgress(current: number, total: number): string {
        const currentHours: number = Math.floor(current / 3600);
        const currentMinutes: number = Math.floor(current % 3600 / 60);
        const currentSeconds: number = Math.floor(current % 3600 % 60);
        const totalHours: number = Math.floor(total / 3600);
        const totalMinutes: number = Math.floor(total % 3600 / 60);
        const totalSeconds: number = Math.floor(total % 3600 % 60);

        if (totalHours > 0) {
            return `${currentHours.toString()}:${currentMinutes.toString().padStart(2, '0')}:${currentSeconds.toString().padStart(2, '0')} 
                / ${totalHours.toString()}:${totalMinutes.toString().padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`;
        }
        else {
            return `${currentMinutes.toString()}:${currentSeconds.toString().padStart(2, '0')} 
                / ${totalMinutes.toString()}:${totalSeconds.toString().padStart(2, '0')}`;
        }
    }

    export function getBase64Size(value: string): number {
        let length: number = (value.length * (3/4));
        if (value.endsWith('=')) {
            length -= value.endsWith('==') ? 2: 1;
        }
        return length;
    }

    export function getRelativeUrl(url: string): string {
        return url.replace(/^(?:\/\/|[^/]+)*\//, '');
    }
}